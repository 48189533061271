<template>
  <div class="login-wrapper">
    <div class="login-content">
      <!-- <div class="left">
        <img src="@/assets/images/login_bg.png" />
        <div class="text-box">
          <div class="label">欢迎登录</div>
          <div class="title">市南区企业服务智慧平台</div>
          <div class="title">（楼宇经济平台）</div>
        </div>
      </div> -->
      <div class="right">
        <div class="tabs">
          <div
            class="tabs-item"
            :class="{ active: currentTab === 'pwd' }"
            @click="() => (currentTab = 'pwd')"
          >
            账号登录
          </div>
          <!-- <div
            class="tabs-item"
            :class="{ active: currentTab === 'phone' }"
            @click="() => (currentTab = 'phone')"
          >
            验证码登录
          </div> -->
        </div>

        <div class="form-box">
          <el-form :model="state.ruleForm" :rules="state.rules" ref="ruleForm" label-width="0px">
            <div v-if="currentTab === 'pwd'">
              <el-form-item prop="account">
                <el-input v-model="state.ruleForm.account" prefix-icon="el-icon-user"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  v-model="state.ruleForm.password"
                  prefix-icon="el-icon-lock"
                  autocomplete="off"
                  show-password
                ></el-input>
              </el-form-item>
            </div>

            <div v-else>
              <el-form-item prop="phone">
                <el-input v-model="state.ruleForm.phone" prefix-icon="el-icon-phone"></el-input>
              </el-form-item>

              <el-form-item prop="captcha">
                <el-input
                  v-model="state.ruleForm.captcha"
                  prefix-icon="el-icon-lock"
                  type="password"
                  autocomplete="off"
                >
                  <template #append>
                    <div class="captcha" @click="handleCaptcha">
                      获取验证码
                    </div>
                  </template>
                </el-input>
              </el-form-item>
            </div>
          </el-form>

          <div class="auto-login-forget">
            <div class="auto-login">
              <el-checkbox v-model="checked" :disabled="currentTab === 'phone'"
                >记住账号</el-checkbox
              >
            </div>
            <div class="forget" @click="handleForget">忘记密码</div>
          </div>

          <div class="commit-btn">
            <el-button
              type="primary"
              style="width:100%"
              @click="handleClick"
              :disabled="logining"
              :loading="logining"
              >登录</el-button
            >
          </div>

          <div class="regist">
            <!-- 注册账号 -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { ElNotification } from 'element-plus'
import storage from 'store'
import { ACCOUNT_PWD } from '@/store/mutation-types'
export default {
  name: 'Login',

  setup: () => {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const currentTab = ref('pwd')
    const checked = ref(false)
    const ruleForm = ref()
    const logining = ref(false)

    const state = reactive({
      ruleForm: {
        account: '',
        password: '',
        // account: '市南区大屏',
        // password: 'pwd123456',
        phone: '',
        captcha: ''
      },
      rules: {
        account: [{ required: true, message: '请输入账号', trigger: 'change' }],
        password: [{ required: true, message: '请输入密码', trigger: 'change' }],
        phone: [{ required: true, message: '请输入手机号码', trigger: 'change' }],
        captcha: [{ required: true, message: '请输入验证码', trigger: 'change' }]
      }
    })

    const accountPWD = storage.get(ACCOUNT_PWD)
    if (accountPWD) {
      checked.value = true
      state.ruleForm.account = accountPWD.account
      state.ruleForm.password = accountPWD.password
    }

    const handleCaptcha = () => {
      console.log('获取验证码')
    }

    const loginSuccess = () => {
      if (checked.value) {
        storage.set(ACCOUNT_PWD, {
          account: state.ruleForm.account,
          password: state.ruleForm.password
        })
      } else {
        storage.remove(ACCOUNT_PWD)
      }
      router.push({ path: '/home' })
    }

    const requestFailed = res => {
      ElNotification.error({
        title: '登录失败',
        message: res.message || '登录失败，请重试！'
      })
    }

    const handleClick = () => {
      const params = {}
      const { account, password, phone, captcha } = state.ruleForm
      if (currentTab.value === 'phone') {
        params.phone = phone
        params.captcha = captcha
      } else {
        params.account = account
        params.password = password
      }

      ruleForm.value.validate(valid => {
        if (valid) {
          logining.value = true
          store
            .dispatch('login', params)
            .then(res => loginSuccess())
            .catch(res => requestFailed(res))
            .finally(() => {
              logining.value = false
            })
        } else {
          setTimeout(() => {
            logining.value = false
          }, 600)
        }
      })
    }

    const handleForget = () => {
      ElNotification.warning({
        title: '友情提示',
        message: '请联系管理员'
      })
    }

    onMounted(() => {
      // 链接带用户名密码时直接登录
      if (route.query.account && route.query.password) {
        checked.value = true
        state.ruleForm.account = route.query.account
        state.ruleForm.password = route.query.password
        handleClick()
      }
    })

    return {
      logining,
      ruleForm,
      currentTab,
      state,
      handleCaptcha,
      checked,
      handleClick,
      handleForget
    }
  }
}
</script>

<style lang="less" scoped>
@fontColor: fade(#4a4a4a, 40%);
@activeFontColor: #4a4a4a;

.login-wrapper {
  width: 100vw;
  height: 100vh;
  // background-image: url('../../../assets/login_bgc.svg');
  background-image: url('https://softya-test.oss-cn-chengdu.aliyuncs.com/snq/login_bgc.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  // display: flex;
  // justify-content: center;
  // align-items: center;

  .login-content {
    position: absolute;
    top: 22%;
    right: 18%;
    width: 450px;
    height: 550px;
    line-height: 22px;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 70px;
    border-radius: 4px;
  }

  .left {
    width: 400px;
    height: 425px;
    position: relative;
    color: #fff;

    .text-box {
      padding: 50px 35px;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      text-align: center;

      .label {
        font-size: 16px;
        color: #eafc91;
        margin-bottom: 30px;
      }

      .title {
        font-size: 24px;
        margin-bottom: 16px;
        font-weight: 300;
      }
    }

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  .right {
    width: 100%;
    // height: 425px;

    .tabs {
      display: flex;

      .tabs-item {
        margin-right: 30px;
        color: @fontColor;
        cursor: pointer;
        font-size: 22px;
        line-height: 44px;
        color: #333333;
        margin-top: 4px;
      }

      .active {
        color: @activeFontColor;
        font-weight: bold;
      }
    }

    .form-box {
      padding-top: 48px;
    }

    .captcha {
      cursor: pointer;
    }
  }

  :deep(.el-input__icon) {
    color: #2574ff;
  }

  .auto-login-forget {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;

    .forget {
      font-size: 14px;
      color: #2574ff;
      cursor: pointer;
    }
  }

  .commit-btn {
    margin-bottom: 30px;
  }

  .regist {
    text-align: center;
    color: #2574ff;
    font-size: 14px;
  }
}
</style>
